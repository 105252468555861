<template>
    <div class="reqBoardList" v-if="dataList.length > 0">

        <el-card class="box-card" v-for="item in dataList" >
            <div slot="header" class="req-head" style="text-align: left; padding: 0px 10px">
                <el-button style="" type="text" v-if="item.sheet_id != null" @click="downloadExam(item)" >下载答题卡</el-button>
                <el-button style="margin-left: 15px;" type="text" v-if="item.sheet_id == null" @click="delReq(item)" >删除</el-button>
                <el-button style="margin-left: 15px;" type="text" v-if="item.sheet_id != null" @click="updateReq(item)" >不再显示</el-button>
            </div>
            <el-row>
                <el-col :span="24" style="text-align: left;">
                    {{item.req_content}}
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="4" v-for="m2 in item.list" class="upload-file">
                    <img src="../../../assets/dashboard/word.png" v-if="m2.ext_name == 'doc' || m2.ext_name == 'docx'"  />
                    <img src="../../../assets/dashboard/excel.png" v-if="m2.ext_name == 'xls' || m2.ext_name == 'xlsx'"  />
                    <img src="../../../assets/dashboard/excel.png" v-if="m2.ext_name == 'pdf'"  />
                    <img src="../../../assets/dashboard/Image.png" v-if="m2.ext_name == 'jpg' || m2.ext_name == 'png' || m2.ext_name == 'jpeg'"  />
                    <br>
                    <a @click="downloadFile(m2)">{{m2.name}}</a>
                </el-col>
            </el-row>

        </el-card>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import XEUtils from "xe-utils";
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";
    import {getDomain} from "@/utils/mUtils";

    export default {
        name: "reqBoard",
        data(){
            return {
                loading: false,
                dataList: [],

            }
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        mounted() {
            this.loadList();
        },
        methods: {

            loadList(){
                this.loading = true;
                this.selExam = null;
                this.archiveList = [];
                let search = { licenceId: this.userInfo.licenceId, status: '00',
                    teacherId: this.userInfo.id, orderBy: 'r.uddt desc'};

                let param = { controllerName: 'reqExam', methodName: '/list', pageNo: 1, pageSize: 999, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.dataList = list;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            downloadFile(item) {
                this.loading = true;
                let h = window.location.protocol;
                let cdnName = item.oss_cdn_name;
                cdnName = cdnName.replace("http:", h);
                saveAs(cdnName, item.name);
                this.loading = false;
            },
            downloadExam(row){
                window.location.href = getDomain() +'/scantronjava/scantron/api/list/download?id='+row.sheet_id;
            },
            updateReq(row){
                let that = this;
                this.$confirm('此操作将永久不在列表中显示, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let param = { controllerName: 'reqExam', methodName: '/saveOrUpdate', param: {id: row.id, status: '02'}};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        console.log('req', code);
                        if (code == 0) {
                            that.$message({message: '操作成功', type: 'success', duration: 3000});
                        }else{
                            that.$message({message: '操作失败。。', type: 'error', duration: 3000});
                        }
                        that.loadList();
                    }).catch((error) => {

                    });
                }).catch(() => {

                });
            },
            delReq(row){
                let that = this;
                this.$confirm('此操作将永久删除该需求以及附件, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let param = { controllerName: 'reqExam', methodName: '/delete', param: {id: row.id}};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        console.log('req', code);
                        if (code == 0) {
                            that.$message({message: '删除成功', type: 'success', duration: 3000});
                        }else{
                            that.$message({message: '删除失败。。', type: 'error', duration: 3000});
                        }
                        that.loadList();
                    }).catch((error) => {

                    });
                }).catch(() => {

                });


            },
        }
    }
</script>

<style scoped  lang="scss" type="text/scss" rel="stylesheet/scss">
    .reqBoardList {
        background-color: white;
        padding: 20px;
        margin-top: 20px;
        border-radius: 15px;
        ::v-deep .el-card__header {
            padding: 10px 10px;
        }
        .req-head {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
        }
        .upload-file {
            img {
                width: 50px;
                height: 50px;
            }
            a {
                cursor: pointer;
            }
        }
    }
</style>
