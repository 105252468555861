<template>
    <div class="reqBoard">
        <el-row>
            <el-col :span="20" style="text-align: left;margin-bottom: 10px;">
                <el-button type="primary" :loading="loading" @click="newReq">新建考试需求</el-button>
            </el-col>
        </el-row>
        <el-card class="box-card" >
            <div slot="header" class="req-head">
                <span>上传文件：试卷，客观题标准答案，阅卷任务分配名单</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="cancel">关 闭</el-button>
            </div>
            <el-row>
                <el-col :span="16">
                    <el-row>
                        <el-col :span="24" style="margin-bottom: 8px;">
                            <el-input v-model="reqForm.reqContent" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"
                                      placeholder="请描述考试时间、关联的课程"></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-upload action :multiple="true"
                                       class="upload-demo" :action="uploadUrl" :headers="uploadHeader"
                                       accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, image/*"
                                       :limit="20" :show-file-list="false" :on-change="handleChange" :auto-upload="true">
                                <el-button size="small" type="primary">上传文件</el-button>
                            </el-upload>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row>
                        <el-col :span="24" style="text-align: left;">
                            <span style="font-size: 14px;">已上传文件：</span>
                        </el-col>
                        <el-col :span="6" v-for="item in reqForm.detailIds" v-if="reqForm.detailIds.length > 0" class="upload-file">
                            <img src="../../../assets/dashboard/word.png" v-if="item.extName == 'doc' || item.extName == 'docx'"  />
                            <img src="../../../assets/dashboard/excel.png" v-if="item.extName == 'xls' || item.extName == 'xlsx'"  />
                            <img src="../../../assets/dashboard/excel.png" v-if="item.extName == 'pdf'"  />
                            <img src="../../../assets/dashboard/Image.png" v-if="item.extName == 'jpg' || item.extName == 'png' || item.extName == 'jpeg'"  />
                            <br>
                            <a @click="downloadFile(item)">{{item.name}}</a>

                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>

        <el-card class="box-card" v-for="item in dataList" >
            <div slot="header" class="req-head">
                <span>需求</span>
                <el-button style="float: right; padding: 3px 0" type="text">关 闭</el-button>
            </div>
            <el-row>

            </el-row>
        </el-card>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import XEUtils from "xe-utils";
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";

    export default {
        name: "reqBoard",
        data(){
            return {
                loading: false,
                reqForm: { id: null, reqContent: null, detailIds: []},
                dataList: [],
                uploadUrl: null,
                uploadHeader: {
                    'Company-url': 'http://www.szpcyl.com',
                    'X-Powered-By': 'raozq',
                    'ke-Token': 'noLogin',
                    'ke-Domain': 'WB',
                },
            }
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
                userToken: state => state.user.token,
            })
        },
        mounted() {
            let myHost = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
            this.uploadUrl = myHost + 'reqExamAttachment/upload';
            this.uploadHeader["ke-Token"] = this.userToken;
        },
        methods: {
            newReq(){
                this.reqForm = { id: null, licenceId: null, schoolId: null, teacherId: null, reqContent: null, detailIds: []};
            },
            handleChange(file, fileList) {
                console.log(file, fileList);
                if(file && "success" == file.status){
                    let resp = file.response;
                    if(resp && ("file" in resp) && ("id" in resp.file)){
                        this.$message({ type: 'success', message: '上传成功!'});
                        this.reqForm.detailIds.push({id: resp.file.id, name: resp.file.name, extName: resp.file.extensionName, ossName: resp.file.ossName});
                    }

                }
            },
            downloadFile(item) {
                this.loading = true;
                saveAs(window.g.OSS_HOST+item.ossName+"?_=" + (new Date()).getTime(), item.name);
                this.loading = false;
            },
            cancel(){
                this.$emit('cancel');
            },
            submitForm(){

                if(XEUtils.isEmpty(this.reqForm.reqContent)){
                    this.$alert('内容必须填写！');
                    return ;
                }
                if(this.reqForm.detailIds.length == 0){
                    this.$alert('请上传文件！');
                    return ;
                }
                this.reqForm.licenceId = this.userInfo.licenceId;
                this.reqForm.schoolId = this.userInfo.schoolId;
                this.reqForm.teacherId = this.userInfo.id;
                let param = { controllerName: 'reqExam', methodName: '/saveNew', param: this.reqForm};

                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('req', code);
                    if (code == 0) {
                        this.$emit('ok');
                    }else if(code == -3){
                        this.$message({message: '保存失败。。', type: 'error', duration: 3000});
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped  lang="scss" type="text/scss" rel="stylesheet/scss">
    .reqBoard {
        background-color: white;
        padding: 20px;
        margin-top: 20px;
        border-radius: 15px;
        .req-head {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
        }
        .upload-file {
            img {
                width: 50px;
                height: 50px;
            }
            a {
                cursor: pointer;
            }
        }
    }
</style>