<template>

    <div class="dataCircle" :style="myStyle">
        <div :style="valueStyle">
            <span class="valueRow" style="font-weight: bold;">{{value}}</span>
        </div>
        <div :style="labelStyle">
            <span class="labelRow">{{label}}</span>
        </div>
    </div>

</template>

<script>

  import {mapState, mapActions} from 'vuex'
  import * as simpleApi from "request/simple";

  export default {
    data(){
      return {

      }
    },
    components: {

    },
    computed:{
        myStyle() {
            return {
                color: this.fontColor,
                background: "-webkit-gradient(linear, 0% 0%, 0% 100%,from("+this.colorFrom+"), to("+this.colorTo+"))",
                height: this.height+"px", width: this.width+"px",
            }
        },
        valueStyle(){
            return { height: this.height/3.5+"px", paddingTop: this.height/5+"px" }
        },
        labelStyle(){
            return { height: this.height/4+"px",  }
        }

    },
    props: {
        label: {type: String},
        value: {type: String},
        colorFrom: { type: String, default: "#3087FF"},
        colorTo: { type: String, default: "#ff0202"},
        fontColor: { type: String, default: "white"},
        height: { type: Number, default: 100},
        width: { type: Number, default: 100},
    },
    created(){
    },
    mounted(){


    },
    methods: {

      
    }
  }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">
  .dataCircle{
    border-radius: 50%;
      width: 100px;
      height: 100px;
      .valueRow {
        font-size: 18px;
      }
      .labelRow {
          font-size: 16px;
      }
  }

</style>
