<template>
  <div class="dashboard-main">
    <div class="home">
      <div class="dboard_menu" ref="menu_right" :style="{left:sidebar.width+'px', height: height+'px'}">
        <header class="head-nav rflex " id='header_container'>
          <div class="menu_page_top rflex">
            <img class='logo' src="@/assets/logo_small.png" alt="vknow" >
            <span class='title' v-show="sidebar.opened">阅卷</span>
          </div>
          <div class="right-nav" ref="rightNav">
            <div class="left-school rflex" >
              <span>{{userInfo.licenceName}}</span>
            </div>
            <top-menu></top-menu>
            <div class="userinfo-right rflex">

              <div class="userinfo">
                <el-menu
                        class="el-menu-demo"
                        mode="horizontal"
                >

                  <el-submenu index="2"  popper-class="infoItem">
                    <template slot="title">
                      <div class='welcome'>
                        <span class="name">您好,</span>
                        <span class='name avatarname'>{{userInfo.nickName}}</span>
                      </div>
                      <img src="@/assets/img/avatar.png" class='avatar' alt="">
                    </template>
                                                <el-menu-item index="2-2" @click="setDialogInfo('pass')">修改密码</el-menu-item>
                    <el-menu-item index="2-3" @click="setDialogInfo('logout')">退出</el-menu-item>
                  </el-submenu>
                </el-menu>
              </div>
            </div>
          </div>
        </header>
        <div class="menu_content" ref="menu_content">
          <section class="dashboard" ref="data_section" :style="{'height':headNavHeight+'px'}">
<!--            <div style="margin-top: 10px;"></div>-->
            <div class="d-row">
              <el-row class="d-menu-row" type="flex">
                <el-col v-for="(c, i) in userData" :span="4" style="padding: 14px 14px 14px 0px" >
                  <div class="item">
                    <img :src="c.imgSrc"  />
                    <router-link  v-if="(!c.url || c.url == '') && ('path' in c)" :to="c.path" :key="i">
                      <span :style="{color: c.fontColor||'black'}">{{c.name}}</span>
                    </router-link>
                    <a v-if="('url' in c) && c.url != ''" target="_blank" :href="c.url">
                      <span :style="{color: c.fontColor||'black'}">{{c.name}}</span>
                    </a>
                    <a v-if="('key' in c)" @click="menuClick(c)" :href="c.url">
                      <span :style="{color: c.fontColor||'black'}">{{c.name}}</span>
                    </a>
                  </div>
                </el-col>
              </el-row>
            </div>
            <reqBoard v-if="userInfo.role == 'teacher' && isReq" @ok="reqOK" @cancel="reqCancel" ></reqBoard>
            <teacherDataBoard ref="teacherDataBoard" v-if="userInfo.role == 'teacher'"></teacherDataBoard>
            <reqBoardList ref="reqBoardList" v-if="userInfo.role == 'teacher'" ></reqBoardList>
            <sclAdminDataBoard ref="sclAdminDataBoard" v-if="userInfo.role == 'sclAdmin'"></sclAdminDataBoard>

          </section>
        </div>
        <backTop :ele="$refs.menu_right"></backTop>
      </div>
    </div>
    <changePassDialog ref="changePassDialog" :changePass="myChangePass"></changePassDialog>
  </div>



</template>

<script>

  import {mapActions, mapGetters, mapState} from 'vuex'
  import util from '@/common/util'
  import * as simpleApi from "request/simple";
  import topMenu from "../components/layout/topMenu";
  import XEUtils from "xe-utils";
  import HeadNav from '../components/layout/headNav.vue';
  import backTop from '../components/backTop';
  import changePassDialog from "../components/layout/changePassDialog";
  import teacherDataBoard from "./dashBoard/teacher/dataDashBoard";
  import reqBoard from "./dashBoard/teacher/reqBoard";
  import reqBoardList from "./dashBoard/teacher/reqBoardList";
  import sclAdminDataBoard from "./dashBoard/sclAdmin/dataDashBoard";

  export default {
    components:{
      HeadNav, backTop, topMenu, changePassDialog, teacherDataBoard, sclAdminDataBoard, reqBoard, reqBoardList
    },
    data(){
      return {
        isInCefClient: false, userData: [], myChangePass:{id: null}, tableData: [], loading: false, isReq: false, height: 1920,
        searchForm:{ pageNo: 1, pageSize: 20, totalCount: 0, searchText: null, schoolId: null},
        data: [{
          name: "基础信息管理", imgSrc: require("assets/dashboard/基础信息管@2x.png"), path: "/mainPage/schoolList", fontColor: "#FC4D7A",  allows: ['admin'],
        }, {
          name: "用户管理", imgSrc: require("assets/dashboard/用户管理@2x.png"), path: "/mainPage/studentList", fontColor: "#35CCB4",  allows: ['admin'],
        }, {
          name: "数据统计", imgSrc: require("assets/dashboard/exam.jpg"), path: "/mainPage/dataDashBoard", fontColor: "#4DB0FC",  allows: ['admin'],

        }, {
          name: "用户管理", imgSrc: require("assets/dashboard/index_user.png"), path: "/mainPage/scl/studentList",  allows: ['sclAdmin'],
        }, {
          name: "数据统计", imgSrc: require("assets/dashboard/exam.jpg"), path: "/mainPage/dataDashBoard2", fontColor: "#4DB0FC", allows: ['sclAdmin'],
          // 教师
        }, {
          name: "用户管理", imgSrc: require("assets/dashboard/用户管理@2x.png"), path: "/mainPage/tch/teachingClassList", fontColor: "#35CCB4", allows: ['teacher'],
          //教师 笔试考试
        // }, {
        //   name: "笔试考试", imgSrc: require("assets/dashboard/index_exam.png"),  url: "https://www.51obe.com/exam/#/exam-manage/index", allows: ['teacher'],
        }, {
          name: "笔试考试", imgSrc: require("assets/dashboard/笔试考试@2x.png"),  path: "/mainPage/exam/newExam", fontColor: "#F5C600", allows: ['teacher', 'sclAdmin', 'admin'],
        },{
          name: "答题卡",  imgSrc: require("assets/dashboard/答题卡@2x.png"),  url: "/scantron/#/list/index", fontColor: "#7857FF", allows: ['teacher'],
        }, {
          name: "扫描管理", imgSrc: require("assets/dashboard/扫描管理@2x.png"), path: "/mainPage/cef/scan", fontColor: "#FC4D7A", allows: ['teacher'],

        }, {
          name: "我的成绩", imgSrc: require("assets/dashboard/index_score.png"), path: "/mainPage/myScoreList", allows: ['student'],

        }, {
          name: "成绩管理",imgSrc: require("assets/dashboard/成绩管理@2x.png"), path: "/mainPage/examsAnalyse", fontColor: "#4DB0FC",  allows: ['teacher'],

        }, {
          name: "考试需求",imgSrc: require("assets/dashboard/考试需求@2x.png"), key: "requestExam", fontColor: "#4D66FC",  allows: ['teacher'],

        // }, {
        //   name: "达成度", imgSrc: require("assets/dashboard/dachengdu.png"), path: "/mainPage/degree/degreeList", allows: ['teacher'], hosts: ['www.51obe.com'],
        }, {
          name: "软件下载", imgSrc: require("assets/dashboard/download.jpeg"), path: "/mainPage/softList", allows: ['teacher'],
          // }, {
          //     name: "总评表管理", imgSrc: require("el-icon-user", allows: ['teacher'],
          //     children: []
        }],
      }
    },

    computed:{
      ...mapGetters('user', [
        'permission_routers',
        'isCollapse',
        'sidebar',
        'menuIndex'
      ]),
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),

      headNavHeight(){
        return document.body.clientHeight - 160;
      },
    },
    created(){
      this.loadUserMenu();
    },
    mounted(){
      if(typeof scanManager == 'undefined') {
        this.isInCefClient = false;
      }else{
        this.isInCefClient = true;
      }
      this.myChangePass.id = this.userInfo.id;
      this.height = document.body.clientHeight;
      this.loadPing();

    },
    methods: {
      ...mapActions('user',['setUserInfo']),
      isInHost(h){
        let host = window.location.host;
        return h == host;
      },
      loadUserMenu(){
        let that = this;
        let role = this.userInfo.role;
        this.userData = [];
        for (let i=0; i<this.data.length; i++){
          let d = this.data[i];
          let allows = d.allows;
          let hosts = d.hosts;
          if(hosts && hosts.length > 0){
            let ht = XEUtils.find(hosts, item => that.isInHost(item));
            if(!ht) continue;
          }
          let ar = XEUtils.find(allows, item => {
            return item == role;
          });
          if(ar){
            if(d.name == "扫描管理"){
              if(this.isInCefClient){
                d.path = "/mainPage/cef/scan";
              }else{
                d.path = "/mainPage/scanPreResult";
              }
            }
            this.userData.push(d);
          }
        }
      },
      loadPing(){
        let param = { controllerName: 'ping', methodName: '/test'};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          console.log("ping", code, data)
        }).catch(() => {

        });
      },
      reqOK(){
        this.isReq = false;
        this.$refs.reqBoardList.loadList();
      },
      reqCancel(){
        this.isReq = false;
      },
      showChangePass(){
        console.log("showChangePass")
        this.$refs.changePassDialog.showDialog();
      },
      setDialogInfo(cmditem) {
        switch (cmditem) {
          case 'pass':
            this.showChangePass();
            break;
          case 'logout':
            this.logout();
            break;
        }
      },
      menuClick(row){
        console.log("menuClick", row);
        if(row.key == 'requestExam'){
          this.isReq = true;
        }
      },
      logout(){
        let that = this;
        this.$confirm('此操作将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          simpleApi.post({controllerName: 'admin', methodName: '/logout', param: { token: this.userInfo.token }}).then(({data, code}) =>  {
            this.setUserInfo(null);
          });
          that.setUserInfo(null);
          setTimeout(that.$router.push({ name: "login"}), 200);
        }).catch(() => {

        });

      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .dashboard-main{

    .home{

      .dboard_menu{
        overflow: auto;
        background:#F6F7FC;
        .head-nav {
          position: fixed;
          top: 0;
          right: 0;
          z-index: 29;
          width: 100%;
          transition: width .2s;
          justify-content: space-between;
          height: 60px;
          box-sizing: border-box;
          background: #fff;

          .logout {
            vertical-align: middle;
            cursor: pointer;
          }
          .menu_page_top{
            width:120px;
            height: 60px;
            align-items: center;
            justify-content: space-around;
            text-transform: uppercase;
            box-sizing: border-box;
            box-shadow:0px 2px 5px 0px rgba(230,224,224,0.5);
            .logo {
              height: 36px;
              width: 36px;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
            }
            .closeLogo{
              width:30px;
              height:30px;
            }
            .title{
              font-size: 22px;
              background-color: transparent;
              border: none;
              color: black;
              i{
                color:#02b6fd;
              }
            }
          }
        }
        .menu_content{
          position: relative;
          margin-top:60px;
          width:100%;
          background:#f0f2f5;
        }

      }
    }

    .dashboard{
      padding: 10px 50px;

      .d-row {
        padding: 10px 0px;
        margin-top: 10px;
        background-color: transparent;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        .d-title{
          text-align: left;
          padding-left: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(150, 151, 153, 0.23);
          span{
            padding: 10px 10px;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .d-menu-row {

          .item{
            padding: 24px 18px;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            flex-direction: row !important;
            align-items: center;
            background-color: white;
            -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            img{
              width: 70px;
              height: 70px;
            }
            span{
              font-size: 24px;
            }
          }
          img{
            width: 40px; height: 40px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            vertical-align: middle;
          }
          span {
            margin-left: 20px;
          }
          a {
            color: black;
            cursor: pointer;
          }
        }
      }

    }
  }


</style>
