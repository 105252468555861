<template>

    <div class="dataCircle">
        <div :id="id" :style="{height:calcHeight+'px',width:calcWidth}"/>

    </div>

</template>

<script>

    import {mapState, mapActions} from 'vuex'
    import * as mUtils from '@/utils/mUtils'
    import echarts from 'echarts'
    require('echarts/theme/macarons')
    import * as simpleApi from "request/simple";

    export default {
        data() {
            return {
                chart: null,
                chartOption: {
                    xAxis: {
                        type: 'category',
                        boundaryGap: false, margin: 15,
                        data: [], axisLabel: { rotate: 45},
                    },
                    grid:{//直角坐标系内绘图网格
                        show:true,//是否显示直角坐标系网格。[ default: false ]
                        left:"20%",//grid 组件离容器左侧的距离。
                        right:"30px",
                        borderColor:"#c45455",//网格的边框颜色
                        bottom:"20%" //
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: [],
                        type: 'line',
                        areaStyle: {}
                    }]
                }

            }
        },
        components: {},
        watch: {
            dataList: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.initChart();
                    }
                }
            },
        },
        computed: {
            calcHeight(){
                return this.height;
            },
            calcWidth(){
                if(this.width == 0) return "100%";
                return this.width+"px";
            },
        },
        props: {
            id:  {
                type: String,
                default: 'examLineChart'
            },
            dataList: {
                type: Object
            },
            width: {
                type: Number,
                default: 80
            },
            height: {
                type: Number,
                default: 120
            },

        },
        mounted() {
            this.myHeight = this.height;
            let that = this;
            this.$nextTick(() => {
                that.initChart();
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        created() {

        },

        methods: {
            initChart() {
                // console.log('initChart', document.getElementById("myBarChart"));
                let xList = [];
                let vList = [];
                if(this.dataList != null && this.dataList.length > 0){
                    try{
                        if(this.chart == null) this.chart = echarts.init(document.getElementById(this.id));
                    }catch (err) {}
                    if(this.chart != null) this.chart.resize();
                    this.chart.resize();

                    for (let i=0; i<this.dataList.length; i++){
                        let d = this.dataList[i];
                        xList.push(d.semester);
                        vList.push(d.cou);
                    }
                    console.log('saleBar ', xList, vList);
                    this.chartOption.xAxis.data = xList;
                    this.chartOption.series[0].data = vList;
                }
                this.setOptions(this.chartOption)
            },
            setOptions({ expectedData, actualData } = {}) {
                if(this.chart != null)  this.chart.setOption(this.chartOption);

            }
        }
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
    .dataCircle {
        border-radius: 2px;

        .row_list {
            .row_base {
                padding: 10px;
                box-sizing: border-box;
                background: #fff;
                border-radius: 6px;
                height: 120px;
            }
        }


    }

</style>
