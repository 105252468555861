<template>

    <div class="dataDashBoard">


            <el-card class="box-card" v-for="(item, index) in tableData" v-if="userInfo.role == 'teacher'">
                <div slot="header" class="clearfix" style="text-align: left; padding: 0px 15px">
                    <span :style="{backgroundColor: getExamTypeColor(item.category), padding: '5px', color: 'white', fontSize: '14px'}">{{getExamType(item.category)}}</span>
                    <span style="font-size: 14px;margin-left: 10px;color: #4D4D4D;">{{item.semester}}</span>
                    <span style="font-size: 20px;font-weight: bold; margin-left: 10px;">{{item.name}}(考试号：{{item.id}})</span>
                    <el-button style="float: right; padding: 3px 0; margin-right: 5px;font-size: 20px;" type="text" size="medium" v-if="item.sheet_id != null" @click="downloadExam(item)" >下载答题卡</el-button>
                    <el-button style="float: right; padding: 3px 0; margin-right: 5px;font-size: 20px;" type="text" size="medium"
                               v-if="(item.hardClassIds != null && item.hardClassIds.length >0) || (item.teachingClassIds != null && item.teachingClassIds.length > 0)"
                               @click="outputRealTimeScore(item)" >下载学生成绩</el-button>

                </div>
                <div class="text item" style="text-align: left;">
                    <el-row style="margin-top: 12px;" class="content-row">
                        <el-col :span="10">
                            <span class="col-span">
                                所属单位：{{item.school_name?item.school_name:'无'}}, 阅卷组长：{{item.nick_name}}({{item.user_name}})
                            </span>
                        </el-col>
                        <el-col :span="6">
                            <span class="col-span">
                                试卷总分：{{item.topic_score}}分
                            </span>
                        </el-col>
                        <el-col :span="6">
                            <span class="col-span">
                                学生人数：{{item.kg_count}}
                            </span>
                        </el-col>
                        <el-col :span="2">
                            <span class="col-span" style="font-size: 14px;">
                                扫描件数：{{item.scan_count}}
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px;margin-bottom: 40px;" class="content-row">
                        <el-col :span="10">
                            <span class="col-span">
                                需阅总数：{{item.zg_count}}, 已阅：{{item.zg_yy_count}}
                            </span>
                        </el-col>
                        <el-col :span="6">
                            <span class="col-span">
                                您需阅：{{item.zg_teacher_count}}
                            </span>
                        </el-col>
                        <el-col :span="6">
                            <span class="col-span">
                                您已阅：{{item.zg_yy_teacher_count}}
                            </span>
                        </el-col>
                        <el-col :span="2">
                            <el-button type="primary" size ="medium" icon="search" v-if="item.state< 2" @click='openScoring(item)'>点击阅卷</el-button>
                        </el-col>
                    </el-row>

                </div>
            </el-card>

<!--        <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>-->
    </div>

</template>

<script>

    import {mapActions, mapGetters, mapState} from 'vuex'
    import util from '@/common/util'
    import * as simpleApi from "request/simple";
    import XEUtils from "xe-utils";
    import Pagination from "@/components/pagination";
    import XLSX from "xlsx";
    import {getDomain} from "@/utils/mUtils";

    export default {
        data() {
            return {
                isInCefClient: false, userData: [], myChangePass:{id: null}, tableData: [], loading: false,
                searchForm:{ pageNo: 1, pageSize: 20, totalCount: 0, searchText: null, schoolId: null},

            }
        },
        components: {
            Pagination
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
            headNavHeight() {
                return document.body.clientHeight - 160;
            },
        },
        created() {
        },
        mounted() {
            if(this.userInfo.role == 'teacher'){
                this.loadMyExamList();
            }
        },
        methods: {
            loadMyExamList(){
                this.loading = true;
                let myTeacherId = this.userInfo.id;
                let search = { licenceId: this.userInfo.licenceId, state: 2,
                    teacherId: myTeacherId, orderBy: 'e.id desc'};
                let param = { controllerName: 'akExam', methodName: '/listByTeacher', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {

                    if (data.code == 0) {
                        let tableData = data.result;
                        for (let i=0; i<tableData.length; i++){
                            let d = tableData[i];
                            if(d.rules != null) {
                                let rules = JSON.parse(d.rules);
                                let hardClassIds = [], teachingClassIds = [];
                                if("hardClassList" in rules && rules.hardClassList != null){
                                    for(let j=0; j<rules.hardClassList.length; j++){
                                        let r = rules.hardClassList[j];
                                        if(r.teacherId == myTeacherId){
                                            let myFind = XEUtils.find(hardClassIds, item => { return item == r.hardClassId; });
                                            if(!myFind) hardClassIds.push(r.hardClassId);
                                        }
                                    }
                                }
                                d["hardClassIds"] = hardClassIds;
                                if("teachingClassList" in rules && rules.teachingClassList != null){
                                    for(let j=0; j<rules.teachingClassList.length; j++){
                                        let r = rules.teachingClassList[j];
                                        if(r.teachers != null && r.teachers.length > 0){
                                            let tchId = r.teachers[0].id;
                                            if(tchId == myTeacherId){
                                                let myFind = XEUtils.find(teachingClassIds, item => { return item == r.id; });
                                                if(!myFind) teachingClassIds.push(r.id);
                                            }
                                        }
                                    }
                                }
                                d["teachingClassIds"] = teachingClassIds;
                            }
                        }
                        console.log(tableData)
                        this.tableData = tableData;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;

                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error", error)
                });
            },
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadMyExamList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadMyExamList()
            },
            getExamType(v){
                if(v == 0) return "期中";
                if(v == 1) return "期末";
                if(v == 2) return "补考";
                if(v == 3) return "测验";
                if(v == 4) return "其他";
                return v;
            },
            getExamTypeColor(v){
                if(v == 0) return "#BC8DEE";
                if(v == 1) return "#8693F3";
                if(v == 2) return "#89C3F8";
                if(v == 3) return "#BC8DEE";
                if(v == 4) return "#8693F3";
                return '#000';
            },
            createExcel(data, columns, fileName){
              const lst = [columns];
              data.forEach(({ teaching_name, hard_name, student_no, student_name, kg_score = 0, zg_score = 0 }) => {
                const totalScore = kg_score + zg_score;
                const row = teaching_name !== undefined
                    ? [teaching_name, hard_name, student_no, student_name, kg_score, zg_score, totalScore]
                    : [hard_name, student_no, student_name, kg_score, zg_score, totalScore];
                lst.push(row);
              });
              let wb = XLSX.utils.book_new();
              wb["SheetNames"] = [fileName];
              wb["Sheets"] = { [fileName]: XLSX.utils.aoa_to_sheet(lst) };
              XLSX.writeFile(wb, `${fileName}.xlsx`);
            },
            outputRealTimeScore(item){
                let examId = item.id, hardClassIds = item.hardClassIds;

                let search = { examId: examId, hardClassIds: hardClassIds, teachingClassIds: item.teachingClassIds};
                let param = { controllerName: 'examTask', methodName: '/scoreRealTime', pageNo: 0, pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (code === 0 && data && data.length > 0) {
                      if (item.teachingClassIds.length > 0) {
                        this.createExcel(data, ['教学班', '行政班', '学号', '姓名', '客观题', '主观题', '总分'], '学生成绩');
                      } else {
                        this.createExcel(data, ['行政班', '学号', '姓名', '客观题', '主观题', '总分'], '学生成绩');
                      }
                        // let lst = [['教学班','行政班', '学号', '姓名', '客观题', '主观题', '总分' ]];
                        // for (let i=0; i<data.length; i++){
                        //     let d = data[i];
                        //     let totalScore = 0;
                        //     if(d.kg_score) totalScore = totalScore + d.kg_score;
                        //     if(d.zg_score) totalScore = totalScore + d.zg_score;
                        //     lst.push([d.teaching_name,d.hard_name, d.student_no, d.student_name, d.kg_score, d.zg_score, totalScore]);
                        // }
                        // let sheetNames = ['学生成绩'];
                        // let sheetsList = {};
                        // let wb = XLSX.utils.book_new();
                        // sheetsList['学生成绩'] = XLSX.utils.aoa_to_sheet(lst);
                        // wb["SheetNames"] = sheetNames;
                        // wb["Sheets"] = sheetsList;
                        // XLSX.writeFile(wb, "学生成绩.xlsx");
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            },
            downloadExam(row){
                window.location.href = getDomain() + '/scantronjava/scantron/api/list/download?id='+row.sheet_id;
            },
            openScoring(item){
                let routeData = this.$router.resolve({name: "scoring", query: {e: item.id, }});
                window.open(routeData.href, '_blank');
            },


        }
    }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">
    .dataDashBoard {

        .row_list {
            .row_base {
                padding: 10px;
                box-sizing: border-box;
                background: #fff;
                border-radius: 6px;
                height: 120px;
            }
        }
        .content-row{
        }
        .col-span{
            font-size: 18px;
            color: #4D4D4D;
        }

    }

</style>
