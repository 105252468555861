<template>

    <div class="dataDashboard">
        <el-row >
            <el-col :span="8" v-if="semesterList && semesterList.length > 0" >
                <el-card :style="row1Style">
<!--                    <div slot="header">-->
<!--                        <span >历史考试</span>-->
<!--                        <el-button style="float: right; padding: 3px 0" type="text">...</el-button>-->
<!--                    </div>-->
                    <exam-line-chart :id="'sclExamLine'" :dataList="semesterList" :width="0" :height="height1"></exam-line-chart>
                </el-card>

            </el-col>
            <el-col :span="8" v-if="countList.length > 0" >
                <el-card :style="row1Style">
<!--                    <div slot="header">-->
<!--                        <span>统计信息</span>-->
<!--                        <el-button style="float: right; padding: 3px 0" type="text">...</el-button>-->
<!--                    </div>-->
                    <el-row v-if="countList.length > 0">
                        <el-col v-for="item in countList" span="7" :offset="1" style="margin-bottom: 20px;">
                            <data-circle :label="item.label" :value="item.value" :colorFrom="item.colorFrom" :colorTo="item.colorTo" :fontColor="item.fontColor"></data-circle>
                        </el-col>
                    </el-row>
                </el-card>

            </el-col>

        </el-row>
    </div>

</template>

<script>

    import {mapState, mapActions} from 'vuex'
    import * as mUtils from '@/utils/mUtils'
    import dataCircle from "../dataCircle";
    import * as simpleApi from "request/simple";
    import examLineChart from "../examLineChart";

    export default {
        data() {
            return {
                semesterList: [], tableHeight: 0, countList: [],
                height1: 200, row1Style: {height: 200},
                loading: true,
                countMap: null,

            }
        },
        components: {
            dataCircle, examLineChart
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
            headNavHeight() {
                return document.body.clientHeight - 160;
            },
        },
        created() {
            let clientHeight = document.body.clientHeight;
            let height1 = clientHeight/2-200;
            this.height1 = height1;
            this.row1Style = {height: (height1+80)+"px"};
        },
        mounted() {
            this.loadData();
        },
        methods: {

            loadData() {
                this.countList = [];
                this.semesterList = []
                if (this.userInfo.schoolId != null) {
                    let param = { controllerName: 'dashboard/',  methodName: 'show',  param: {schoolId: this.userInfo.schoolId}}; //licenceId: this.userInfo.licenceId
                    simpleApi.post(param).then(({result: {code, data}, sysCode}) => {
                        console.log('loadData', data)
                        if (data) {
                            this.semesterList = data.semesterList;
                            let countMap = data.countMap;
                            this.countList.push({label: '考试数量', value: countMap.exam_count, colorTo: "#67C23A", colorFrom: "#FFFFFF", fontColor: "#000000"});
                            this.countList.push({label: '扫描数量', value: countMap.log_count, colorTo: "#409EFF", colorFrom: "#FFFFFF", fontColor: "#000000"});
                            this.countList.push({label: '课程数量', value: countMap.course_count, colorTo: "#E6A23C", colorFrom: "#FFFFFF", fontColor: "#000000"});
                            this.countList.push({label: '行政班', value: countMap.hard_class_count, colorTo: "#F56C6C", colorFrom: "#FFFFFF", fontColor: "#000000"});
                            this.countList.push({label: '学生', value: countMap.student_count, colorTo: "#909399", colorFrom: "#FFFFFF", fontColor: "#000000"});
                            this.countList.push({label: '教师', value: countMap.teacher_count, colorTo: "#303133", colorFrom: "#FFFFFF", fontColor: "#000000"});
                        }
                    });
                }
            },


        }
    }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">
    .dataDashboard {
        border-radius: 10px;
        margin-top: 20px;
        background: transparent;
        .data-count {
            padding: 80px 10px 5px 10px;
        }

    }

</style>
